<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      action: {
        type: Function,
        required: true,
      },
    },
  };
</script>
<template>
  <button class="magazineBtn" @click="action">
    {{ text }}
    <span v-for="i in 4" :key="i" class="magazineBtn__buttonSpan"></span>
  </button>
</template>

<style lang="scss">
  .magazineBtn {
    width: 200px;
    height: 50px;
    background: linear-gradient(to left top, #a90606 50%, #bd0909 50%);
    border-style: none;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: $first_font;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-radius: 12px;

    &:hover {
      transition: all 0.5s;
      transform: rotate(-2deg) scale(1.1);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
    }
    &:hover .magazine__buttonSpan {
      animation-play-state: paused;
    }

    &__buttonSpan {
      position: absolute;
      display: block;
    }
    &__buttonSpan:nth-child(1) {
      height: 3px;
      width: 200px;
      top: 0px;
      left: -200px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), #f6e58d);
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
      animation: slideLeftToRight 5s linear infinite;
      animation-delay: 1s;
    }
    &__buttonSpan:nth-child(2) {
      height: 70px;
      width: 3px;
      top: -70px;
      right: 0px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #f6e58d);
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
      animation: slideTopToBottom 5s linear infinite;
      animation-delay: 2s;
    }

    &__buttonSpan:nth-child(3) {
      height: 3px;
      width: 200px;
      right: -200px;
      bottom: 0px;
      background: linear-gradient(to left, rgba(0, 0, 0, 0), #f6e58d);
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
      animation: slideRightToLeft 5s linear infinite;
      animation-delay: 3s;
    }

    &__buttonSpan:nth-child(4) {
      height: 70px;
      width: 3px;
      bottom: -70px;
      left: 0px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0), #f6e58d);
      border-top-right-radius: 1px;
      border-top-left-radius: 1px;
      animation: slideBottomToTop 5s linear infinite;
      animation-delay: 4s;
    }
  }
</style>
