<template>
  <div class="modal-g" @click="handleClickOutside">
    <div class="modal-g__wrapper">
      <div class="modal-g__container" @click.stop>
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer">
          <div class="modal-g__buttons" v-if="showConfirmButton">
            <button class="modal-g__button submit" @click="clickSubmit" ref="submit" autofocus="false">Aceptar</button>
          </div>
        </slot>
      </div>
    </div>
    <div class="modal-g__mask" v-if="showMask" @click="clickCancel"></div>
  </div>
</template>

<script>
  export default {
    props: {
      isCancel: { type: Boolean, default: true },
      isDisableSubmit: { type: Boolean, default: true },
      showMask: { type: Boolean, default: false },
      showConfirmButton: { type: Boolean, default: true },
      blockScroll: { type: Boolean, default: false },
      closeOnClickOutside: { type: Boolean, default: false },
    },
    methods: {
      clickSubmit() {
        this.$emit("onSubmit");
      },
      clickCancel() {
        this.$emit("onCancel");
        if (this.blockScroll) return this.enableScroll();
      },
      disabledButtonSubmit() {
        this.$refs.submit.setAttribute("disabled", "true");
      },
      enabledButtonSubmit() {
        this.$refs.submit.removeAttribute("disabled");
      },
      disableScroll() {
        if (this.blockScroll) return (document.body.style.overflow = "hidden");
      },
      enableScroll() {
        if (this.blockScroll) return (document.body.style.overflow = "");
      },
      handleClickOutside() {
        if (this.closeOnClickOutside) {
          this.clickCancel();
        }
      },
    },
    mounted() {
      if (this.showMask) this.$emit("open");
      this.disableScroll();
    },
  };
</script>

<style lang="scss">
  .modalMagazine.modal-g {
    background: rgba(0, 0, 0, 0.58);
    backdrop-filter: blur(7px);
  }

  .modalMagazine {
    .modal-g__container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      box-shadow: none;
      background: none;
      position: relative;
    }
  }

  .modal-g {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.144);
    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1001;
    }
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: auto;
      max-height: 80vh;
      padding: 10px;
      border-radius: 12px;
      background-color: $white;
      box-shadow: $dshadow;
      overflow-y: auto;
      z-index: 1002;
    }
    &__button {
      font-family: $first_font;
      outline: none;
      border: none;
      font-size: 14px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.36);
      border-radius: 2px;
      color: white;
      background-color: $primary_color;
      padding: $mpadding/2;
      margin: 10px;
      &[disabled] {
        opacity: 0.5;
        &:active {
          transform: initial;
        }
      }
      &.cancel {
        background-color: $gray-emperor;
      }
    }
  }
</style>
