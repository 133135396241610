import React, { useEffect, useState, useCallback, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import PageComponent from "../web/PageComponent";
import style from "./flipbook.module.scss";
import CloseBtn from "../CloseBtnJSX/closeBtn";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const screenSizes = [
  { maxWidth: 400, size: { width: 300, height: 450 } },
  { maxWidth: 600, size: { width: 333, height: 500 } },
  { maxWidth: 1200, size: { width: 400, height: 600 } },
  { maxWidth: 1440, size: { width: 450, height: 675 } },
];

function Flipbook({ translate, closeFunction }) {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookSize, setBookSize] = useState(screenSizes[2].size);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [isSinglePageMode, setIsSinglePageMode] = useState(true);
  const [widthStyle, setWidthStyle] = useState({});
  const [indicative, setIndicative] = useState(translate.slide);
  const [isCoverPage, setIsCoverPage] = useState(true);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [lastPage, setLastPage] = useState(true);
  const [loadPage, setLoadPage] = useState(false);
  const bookRef = useRef(null);

  const lang = localStorage.getItem("user-language") || "es";

  const updateBookSize = useCallback(() => {
    setInnerWidth(window.innerWidth);
    const size = screenSizes.find((item) => innerWidth <= item.maxWidth)?.size || screenSizes[screenSizes.length - 1].size;
    setBookSize(size);
    setIsSinglePageMode(innerWidth <= 800);
    setWidthStyle({ width: isCoverPage && innerWidth >= 800 ? "50%" : "100%" });
  }, [isCoverPage]);

  useEffect(() => {
    updateBookSize();
    window.addEventListener("resize", updateBookSize);
    return () => window.removeEventListener("resize", updateBookSize);
  }, [updateBookSize]);

  const onFlip = (event) => {
    const atCoverPage = event.data === 0;
    const atLastPage = numPages === event.data + 1;
    atLastPage ? setLastPage(false) : setLastPage(true);

    setIsCoverPage(atCoverPage);
    setCurrentPage(event.data + 1);
    setIndicative(atCoverPage ? translate.slide : translate.next);

    if (atLastPage && innerWidth >= 800) {
      setWidthStyle({ float: "left", width: "50%" });
    } else {
      setWidthStyle({ width: "100%" });
    }
  };

  const handleNextPage = () => {
    bookRef.current.pageFlip().flipNext();
  };

  const handlePrevPage = () => {
    bookRef.current.pageFlip().flipPrev();
  };

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  useEffect(() => {
    if (!pdfData) {
      (async () => {
        try {
          const response = await fetch(`https://cdn.models1a.com/magazine/magazine.${lang}.tr.pdf`);
          const pdfBlob = await response.blob();
          setPdfData(URL.createObjectURL(pdfBlob));
          setIsLoaded(true);
        } catch (error) {
          console.error("Error fetching PDF:", error);
        }
      })();
    }
  }, [pdfData, lang]);

  return (
    <div className={style.flipbook} style={{ width: isLoaded ? "unset" : "100%" }}>
      {!isLoaded && <div className="loading"></div>}
      {loadPage && <CloseBtn lastPage={lastPage} closeFunction={closeFunction}></CloseBtn>}
      {pdfData && (
        <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} loading={<div className="loading" style={{ opacity: 0 }}></div>}>
          <HTMLFlipBook
            ref={bookRef}
            width={bookSize.width}
            height={bookSize.height}
            drawShadow={true}
            showCover={true}
            mobileScrollSupport={true}
            usePortrait={isSinglePageMode}
            startPage={0}
            onFlip={onFlip}
            maxShadowOpacity={1}>
            {[...Array(numPages)].map((_, index) => (
              <PageComponent key={`page-${index + 1}`} number={index + 1}>
                <Page
                  pageNumber={index + 1}
                  width={bookSize.width}
                  height={bookSize.height}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onLoadSuccess={() => setLoadPage(true)}
                  loading={<div className="loading" style={{ opacity: "0" }}></div>}
                />
              </PageComponent>
            ))}
          </HTMLFlipBook>

          {isLoaded && loadPage && (
            <div className={style.flipbook__indicative} style={widthStyle}>
              {!isCoverPage && (
                <button className={style.flipbook__arrowContainer} onClick={handlePrevPage}>
                  <span className={style.flipbook__textRight}>{translate.previous}</span>
                  <div className={style.flipbook__arrowRight}></div>
                </button>
              )}

              <span className={style.flipbook__subText}>
                {currentPage} - {numPages}
              </span>

              {lastPage && (
                <button className={style.flipbook__arrowContainer} onClick={handleNextPage}>
                  {isCoverPage && (
                    <>
                      <div className={style.flipbook__arrow}></div>
                      <div className={style.flipbook__arrow}></div>
                    </>
                  )}
                  <div className={style.flipbook__arrow}></div>
                  <span className={style.flipbook__text}>{indicative}</span>
                </button>
              )}
            </div>
          )}
        </Document>
      )}
    </div>
  );
}

export default Flipbook;
